<template>
  <div class="h-screen p-5">
    <div class="my-8">
      <h1 class="font-semibold text-xl text-white">Organization’s account</h1>
    </div>
    <LocationsTable 
      :data="usersPayments" 
      :labels="['Name', 'Active', 'Active date', 'Inactive Date', 'Subscription type', 'Amount']"
      @changePage="userPaymentDetails"
    >
      <template v-slot:table-content v-if="usersPayments">
        <tr v-for="usersPayment in usersPayments.results" :key="usersPayment.id" class="bg-white p-5">
          <td class="text-center">
            {{ usersPayment.full_name }}
          </td>
          <td class="text-center">
            <input
              type="checkbox"
              :checked="usersPayment.is_active"
              disabled
            />
          </td>
          <td class="text-center">
            {{ usersPayment.activated_at ? formatDate(usersPayment.activated_at) : "-" }}          
          </td>
          <td class="text-center">
            {{ !usersPayment.is_active && usersPayment.deactivated_at ? formatDate(usersPayment.deactivated_at) : "-" }} 
          </td>
          <td class="text-center">
            {{ usersPayment.interval ? usersPayment.interval : "-" }}
          </td>
          <td class="text-center">
            {{ usersPayment.amount ? usersPayment.amount : "-" }}
          </td>
        </tr>
      </template>
    </LocationsTable>
  </div>
</template>
  
<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import { getUserPaymentDetails } from "@/services/user/user.js";
import LocationsTable from "@/components/location/LocationsTable.vue";

const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);

const usersPayments = ref(null);

onMounted(async () => {
  await userPaymentDetails()
});

async function userPaymentDetails(page) {
  const data = await getUserPaymentDetails(user.value.location, page);
  usersPayments.value = data;
}

function formatDate(date) {
  const dateAdded = new Date(date);
  return `${
    dateAdded.getMonth() + 1
  }/${dateAdded.getDate()}/${dateAdded.getFullYear()}`;
}
</script>

<style>
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
